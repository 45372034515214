const authRoutes = [
  {
    path: "/overview",
    name: "Yhteenveto",
    meta: { permission: "overview" },
    component: () => import(/* webpackChunkName: "overview" */ "../../views/Overview/Overview.vue"),
  },
  {
    path: "/switch-account",
    name: "Vaihda tiliä",
    meta: { layout: "full", permission: "overview" },
    component: () =>
      import(/* webpackChunkName: "switchaccount" */ "../../views/SwitchAccount.vue"),
  },
  {
    path: "/todos",
    meta: { permission: "todos" },
    component: () => import(/* webpackChunkName: "todo-app" */ "../../views/Todo/TodoApp.vue"),
    children: [
      {
        path: "",
        redirect: "tasks",
        meta: { permission: "todos" },
      },
      {
        path: "tasks",
        name: "Tehtävät",
        meta: { permission: "todos" },
        component: () =>
          import(/* webpackChunkName: "todo-tasks" */ "../../views/Todo/TasksPage.vue"),
      },
      {
        path: "completed",
        name: "Valmiit tehtävät",
        meta: { permission: "todos" },
        component: () =>
          import(/* webpackChunkName: "todo-completed" */ "../../views/Todo/CompletedPage.vue"),
      },
      {
        path: "label/:id",
        name: "Kategorisoidut tehtävät",
        meta: { permission: "todos" },
        component: () =>
          import(/* webpackChunkName: "todo-label" */ "../../views/Todo/LabelPage.vue"),
      },
    ],
  },
  // {
  //   path: "/kanban",
  //   name: "Työvirta",
  //   meta: { permission: "todos" },
  //   component: () => import(/* webpackChunkName: "kanban" */ "../../views/Kanban/Kanban.vue"),
  // },
  {
    path: "/contacts",
    name: "Yhteydenotot",
    meta: { permission: "contacts" },
    component: () => import(/* webpackChunkName: "contacts" */ "../../views/Contact/Contacts.vue"),
  },
  {
    path: "/reservations",
    name: "Varaukset",
    meta: { permission: "reservations" },
    component: () =>
      import(/* webpackChunkName: "reservations" */ "../../views/Reservation/Reservations.vue"),
  },
  {
    path: "/forms",
    name: "Lomakkeet",
    meta: { permission: "forms" },
    component: () => import(/* webpackChunkName: "forms" */ "../../views/Form/Forms.vue"),
  },
  {
    path: "/form-submissions",
    name: "Lomakkeen yhteydenotot",
    meta: { permission: "formSubmissions" },
    component: () =>
      import(/* webpackChunkName: "submissions" */ "../../views/Form/FormSubmissions.vue"),
  },
  {
    path: "/form/create",
    name: "Luo lomake",
    meta: { permission: "createForm" },
    component: () =>
      import(/* webpackChunkName: "create-form" */ "../../components/Form/CreateForm.vue"),
  },
  {
    path: "/form/edit/:id",
    name: "Muokkaa lomaketta",
    meta: { permission: "editForm" },
    component: () =>
      import(/* webpackChunkName: "edit-form" */ "../../components/Form/EditForm.vue"),
  },
  {
    path: "/rental-contracts",
    name: "Vuokrasopimukset",
    meta: { permission: "contracts" },
    component: () =>
      import(
        /* webpackChunkName: "rentalcontracts" */ "../../views/RentalContract/RentalContracts.vue"
      ),
  },
  {
    path: "/rental-contract/create",
    name: "Luo vuokrasopimus",
    meta: { permission: "createContract" },
    component: () =>
      import(
        /* webpackChunkName: "newrentalcontract" */ "../../views/RentalContract/NewRentalContract.vue"
      ),
  },
  {
    path: "/rental-contract/edit/:id",
    name: "Muokkaa vuokrasopimusta",
    meta: { permission: "editContract" },
    component: () =>
      import(
        /* webpackChunkName: "editrentalcontract" */ "../../views/RentalContract/EditRentalContract.vue"
      ),
  },
  {
    path: "/rental-contract/overview/:id",
    name: "Sopimuksen yhteenveto",
    meta: { permission: "contractOverview" },
    component: () =>
      import(
        /* webpackChunkName: "contractoverview" */ "../../views/RentalContract/ContractOverview.vue"
      ),
  },
  {
    path: "/rental-contract/create-old",
    name: "Luo voimassa oleva vuokrasopimus",
    meta: { permission: "createOldContract" },
    component: () =>
      import(
        /* webpackChunkName: "createoldrentalcontract" */ "../../views/RentalContract/CreateOldRentalContract.vue"
      ),
  },
  {
    path: "/rental-contract/edit-old/:id",
    name: "Muokkaa voimassa olevaa vuokrasopimusta",
    meta: { permission: "editOldContract" },
    component: () =>
      import(
        /* webpackChunkName: "editoldrentalcontract" */ "../../views/RentalContract/EditOldRentalContract.vue"
      ),
  },
  {
    path: "/contract-models",
    name: "Sopimuspohjat",
    meta: { permission: "contractModels" },
    component: () =>
      import(
        /* webpackChunkName: "contractmodels" */ "../../views/ContractModels/ContractModels.vue"
      ),
  },
  {
    path: "/contract-model/create",
    name: "Luo vuokrasopimuspohja",
    meta: { permission: "createContractModel" },
    component: () =>
      import(
        /* webpackChunkName: "createcontractmodel" */ "../../views/ContractModels/NewContractModel.vue"
      ),
  },
  {
    path: "/contract-model/edit/:id",
    name: "Muokkaa vuokrasopimuspohjaa",
    meta: { permission: "editContractModel" },
    component: () =>
      import(
        /* webpackChunkName: "editcontractmodel" */ "../../views/ContractModels/EditContractModel.vue"
      ),
  },
  {
    path: "/other-contracts",
    name: "Muut sopimukset",
    meta: { permission: "otherContracts" },
    component: () =>
      import(
        /* webpackChunkName: "othercontracts" */ "../../views/OtherContracts/OtherContracts.vue"
      ),
  },
  {
    path: "/other-contract/create-document",
    name: "Luo muut sopimus",
    meta: { permission: "createOtherContract" },
    component: () =>
      import(
        /* webpackChunkName: "createothercontract" */ "../../views/OtherContracts/CreateOtherContract.vue"
      ),
  },
  {
    path: "/other-contract/edit/:id",
    name: "Muokkaa muuta sopimusta",
    meta: { permission: "editOtherContract" },
    component: () =>
      import(
        /* webpackChunkName: "editothercontract" */ "../../views/OtherContracts/EditOtherContract.vue"
      ),
  },
  {
    path: "/partners",
    name: "Toimeksiantajat",
    meta: { permission: "partners" },
    component: () => import(/* webpackChunkName: "partners" */ "../../views/Partner/Partners.vue"),
  },
  {
    path: "/partner/create",
    name: "Luo uusi toimeksiantaja",
    meta: { permission: "createPartner" },
    component: () =>
      import(/* webpackChunkName: "newpartner" */ "../../views/Partner/NewPartner.vue"),
  },
  {
    path: "/partner/edit/:id",
    name: "Muokkaa toimeksiantajaa",
    meta: { permission: "editPartner" },
    component: () =>
      import(/* webpackChunkName: "editpartner" */ "../../views/Partner/EditPartner.vue"),
  },
  {
    path: "/partner/overview/:id",
    name: "Toimeksiantajan hallinta",
    meta: { permission: "partnerOverview" },
    component: () =>
      import(/* webpackChunkName: "partneroverview" */ "../../views/Partner/PartnerOverview.vue"),
  },
  {
    path: "/tenants",
    name: "Vuokralaiset",
    meta: { permission: "tenants" },
    component: () => import(/* webpackChunkName: "tenants" */ "../../views/Tenant/Tenants.vue"),
  },
  {
    path: "/tenant/create",
    name: "Luo vuokralainen",
    meta: { permission: "createTenant" },
    component: () => import(/* webpackChunkName: "newtenant" */ "../../views/Tenant/NewTenant.vue"),
  },
  {
    path: "/tenant/edit/:id",
    name: "Muokkaa vuokralaista",
    meta: { permission: "editTenant" },
    component: () =>
      import(/* webpackChunkName: "edittenant" */ "../../views/Tenant/EditTenant.vue"),
  },
  {
    path: "/tenant/overview/:id",
    name: "Vuokralaisen hallinta",
    meta: { permission: "tenantOverview" },
    component: () =>
      import(/* webpackChunkName: "tenantoverview" */ "../../views/Tenant/TenantOverview.vue"),
  },
  {
    path: "/realtors",
    name: "Välittäjät",
    meta: { permission: "realtors" },
    component: () => import(/* webpackChunkName: "realtors" */ "../../views/Realtor/Realtors.vue"),
  },
  {
    path: "/condominiums",
    name: "Taloyhtiöt",
    meta: { permission: "condominiums" },
    component: () =>
      import(/* webpackChunkName: "condominiums" */ "../../views/Condominium/Condominiums.vue"),
  },
  {
    path: "/condominium/create",
    name: "Luo taloyhtiö",
    meta: { permission: "createCondominium" },
    component: () =>
      import(/* webpackChunkName: "newcondominium" */ "../../views/Condominium/NewCondominium.vue"),
  },
  {
    path: "/condominium/edit/:id",
    name: "Muokkaa taloyhtiötä",
    meta: { permission: "editCondominium" },
    component: () =>
      import(
        /* webpackChunkName: "editcondominium" */ "../../views/Condominium/EditCondominium.vue"
      ),
  },
  {
    path: "/condominium/overview/:id",
    name: "Taloyhtiön / Kiinteistön yhteenveto",
    meta: { permission: "condominiumOverview" },
    component: () =>
      import(
        /* webpackChunkName: "condominiumoverview" */ "../../views/Condominium/CondominiumOverview.vue"
      ),
  },
  {
    path: "/apartments",
    name: "Vuokrakohteet",
    meta: { permission: "apartments" },
    component: () =>
      import(/* webpackChunkName: "apartments" */ "../../views/Apartment/Apartments.vue"),
  },
  {
    path: "/condominium/:id/create-apartment",
    name: "Luo kohde",
    meta: { permission: "createApartment" },
    component: () =>
      import(/* webpackChunkName: "newapartment" */ "../../views/Apartment/NewApartment.vue"),
  },
  {
    path: "/apartment/edit/:id",
    name: "Muokkaa kohdetta",
    meta: { permission: "editApartment" },
    component: () =>
      import(/* webpackChunkName: "editapartment" */ "../../views/Apartment/EditApartment.vue"),
  },
  {
    path: "/apartment/overview/:id",
    name: "Vuokrakohteen yhteenveto",
    meta: { permission: "apartmentOverview" },
    component: () =>
      import(
        /* webpackChunkName: "apartmentoverview" */ "../../views/Apartment/ApartmentOverview.vue"
      ),
  },
  {
    path: "/apartment-statistics",
    name: "Analysaattori",
    meta: { permission: "apartmentStatistics" },
    component: () =>
      import(
        /* webpackChunkName: "apartmentstatistics" */ "../../views/Apartment/ApartmentStatistics.vue"
      ),
  },
  {
    path: "/invoices",
    name: "Laskut",
    meta: { permission: "invoices" },
    component: () => import(/* webpackChunkName: "invoices" */ "../../views/Invoice/Invoices.vue"),
  },
  {
    path: "/invoice/create/:id",
    name: "Luo lasku",
    meta: { permission: "createInvoice" },
    component: () =>
      import(/* webpackChunkName: "createinvoice" */ "../../views/Invoice/CreateInvoice.vue"),
  },
  {
    path: "/invoice/edit/:id",
    name: "Muokkaa laskua",
    meta: { permission: "editInvoice" },
    component: () =>
      import(/* webpackChunkName: "editinvoice" */ "../../views/Invoice/EditInvoice.vue"),
  },
  {
    path: "/refund-invoices",
    name: "Hyvityslaskut",
    meta: { permission: "refundInvoices" },
    component: () =>
      import(/* webpackChunkName: "refundinvoices" */ "../../views/Invoice/RefundInvoices.vue"),
  },
  {
    path: "/invoice/refund/:id",
    name: "Luo hyvityslasku",
    meta: { permission: "createRefundInvoice" },
    component: () =>
      import(
        /* webpackChunkName: "createrelatedrefundinvoice" */ "../../views/Invoice/CreateRelatedRefundInvoice.vue"
      ),
  },
  {
    path: "/invoice/create-refund-invoice/",
    name: "Luo uusi hyvityslasku",
    meta: { permission: "createRefundInvoice" },
    component: () =>
      import(
        /* webpackChunkName: "createrefundinvoice" */ "../../views/Invoice/CreateRefundInvoice.vue"
      ),
  },
  {
    path: "/invoice/refund/edit-related/:id",
    name: "Muokkaa kohdistettua hyvityslaskua",
    meta: { permission: "editRefundInvoice" },
    component: () =>
      import(
        /* webpackChunkName: "editrelatedrefundinvoice" */ "../../views/Invoice/EditRelatedRefundInvoice.vue"
      ),
  },
  {
    path: "/invoice/refund/edit/:id",
    name: "Muokkaa hyvityslaskua",
    meta: { permission: "editRefundInvoice" },
    component: () =>
      import(
        /* webpackChunkName: "editrefundinvoice" */ "../../views/Invoice/EditRefundInvoice.vue"
      ),
  },
  {
    path: "/outgoing-payments/",
    name: "Lähtevien seuranta",
    meta: { permission: "outgoingPayments" },
    component: () =>
      import(
        /* webpackChunkName: "outgoingpayments" */ "../../views/OutgoingPayments/OutgoingPayments.vue"
      ),
  },
  {
    path: "/rent-increase/",
    name: "Vuokrankorotukset",
    meta: { permission: "rentIncreases" },
    component: () =>
      import(/* webpackChunkName: "rentincreases" */ "../../views/RentIncrease/RentIncreases.vue"),
  },
  {
    path: "/payments/",
    name: "Maksusuoritukset",
    meta: { permission: "payments" },
    component: () =>
      import(/* webpackChunkName: "payments" */ "../../views/Invoice/PaidAmounts.vue"),
  },
  {
    path: "/loans/",
    name: "Lainat",
    meta: { permission: "loans" },
    component: () => import(/* webpackChunkName: "loans" */ "../../views/Loan/Loans.vue"),
  },
  {
    path: "/loan/create/",
    name: "Luo laina",
    meta: { permission: "createLoan" },
    component: () => import(/* webpackChunkName: "createloan" */ "../../views/Loan/NewLoan.vue"),
  },
  {
    path: "/loan/edit/:id",
    name: "Muokkaa lainaa",
    meta: { permission: "editLoan" },
    component: () => import(/* webpackChunkName: "editloan" */ "../../views/Loan/EditLoan.vue"),
  },
  {
    path: "/credit-reports",
    name: "Hae luottotietoraportti",
    meta: { permission: "creditReports" },
    component: () =>
      import(/* webpackChunkName: "creditreport" */ "../../views/CreditReport/CreditReport.vue"),
  },
  {
    path: "/receipts",
    name: "Kulut",
    meta: { permission: "receipts" },
    component: () => import(/* webpackChunkName: "receipts" */ "../../views/Receipts/Receipts.vue"),
  },
  {
    path: "/reports",
    name: "Raportit",
    meta: { permission: "reports" },
    component: () => import(/* webpackChunkName: "reports" */ "../../views/Reports/Reports.vue"),
  },
  {
    path: "/deposits",
    name: "Vakuudet",
    meta: { permission: "deposits" },
    component: () => import(/* webpackChunkName: "deposits" */ "../../views/Deposit/Deposits.vue"),
  },
  {
    path: "/products",
    name: "Tuotteet",
    meta: { permission: "products" },
    component: () => import(/* webpackChunkName: "products" */ "../../views/Product/Products.vue"),
  },
  {
    path: "/key-control",
    name: "Avainhallinta",
    meta: { permission: "keyControl" },
    component: () => import(/* webpackChunkName: "keycontrol" */ "../../views/Keys/KeyControl.vue"),
  },
  {
    path: "/key-holders",
    name: "Avaimenhaltijat",
    meta: { permission: "keyHolders" },
    component: () => import(/* webpackChunkName: "keyholders" */ "../../views/Keys/KeyHolders.vue"),
  },
  {
    path: "/settings",
    name: "Asetukset",
    meta: { permission: "settings" },
    component: () => import(/* webpackChunkName: "settings" */ "../../views/Settings/Settings.vue"),
  },
  {
    path: "/collaborators",
    name: "Yhteistyökumppanit",
    meta: { permission: "collaborators" },
    component: () =>
      import(/* webpackChunkName: "collaborators" */ "../../views/Collaborator/Collaborators.vue"),
  },
  {
    path: "/settings/stripe-success",
    name: "Stripe success",
    meta: { permission: "settings" },
    component: () =>
      import(/* webpackChunkName: "stripesuccess" */ "../../views/Settings/StripeSuccess.vue"),
  },
  {
    path: "/settings/stripe-refresh",
    name: "Stripe refresh",
    meta: { permission: "settings" },
    component: () =>
      import(/* webpackChunkName: "striperefresh" */ "../../views/Settings/StripeRefresh.vue"),
  },
  {
    path: "/open-banking/nordigen",
    name: "Nordigen",
    meta: { permission: "nordigen" },
    component: () =>
      import(/* webpackChunkName: "nordigen" */ "../../views/OpenBanking/Nordigen/Nordigen.vue"),
  },
  {
    path: "/profile",
    name: "kayttajan-asetukset",
    meta: { permission: "profile" },
    component: () =>
      import(/* webpackChunkName: "userprofile" */ "../../views/Settings/UserProfile.vue"),
  },
];

export default authRoutes;
