import moment from "moment";
// import mixins from "./mixins";
import store from "../store/store";
import { roundDecimals } from "@/mixins/utilFunctions";

export default {
  countNewSum(prod, currentItem, newRentIncrease, factors) {
    let newAmount = 0;
    const increaseType = currentItem.lease?.rentIncreaseType || "";

    if (increaseType === "percentage") {
      newAmount = prod.amount * factors.percentageFactor;
    } else if (
      increaseType.toLowerCase().includes("index") &&
      !increaseType.toLowerCase().includes("percentage")
    ) {
      newAmount = newRentIncrease.startRentPrice * factors.indexFactor;
    } else if (
      increaseType.toLowerCase().includes("index") &&
      increaseType.toLowerCase().includes("percentage")
    ) {
      newAmount = newRentIncrease.startRentPrice * factors.indexPlusPercentageFactor;
    } else if (increaseType === "fixed") {
      newAmount = prod.increaseMade ? prod.amount : prod.amount + newRentIncrease.fixedAmount;
    } else if (increaseType === "own") {
      newAmount = prod.amount;
    }

    if (newAmount < prod.amount) return prod.amount;
    else return roundDecimals(newAmount);
  },

  getInvoiceDueDate(baseInvoice, givenDate) {
    let dueDate;
    const newDate = moment(givenDate).format("YYYY-MM");
    const nextDueDate = moment(baseInvoice.nextDueDate).format("YYYY-MM");
    const otherDueDates = baseInvoice.otherDueDates;

    // NEXT DUEDATE IS SAME OR AFTER GIVEN DATE
    if (moment(nextDueDate).isSameOrAfter(moment(newDate))) {
      dueDate = moment(baseInvoice.nextDueDate).format("YYYY-MM-DD");
    }
    // NEXT DUEDATE IS BEFORE GIVEN DATE
    else {
      // calc next possible dueDate
      const otherDueDate =
        otherDueDates === 100
          ? new Date(moment(newDate).endOf("month").format("YYYY-MM-DD")).getDate()
          : otherDueDates;

      const invoiceInterval = baseInvoice.invoiceInterval;
      const timeDiff = moment(newDate).diff(moment(nextDueDate), "months", true);
      const reminder = timeDiff % invoiceInterval;

      // Can be setted as duedate
      if (reminder == 0) {
        dueDate = moment(
          new Date(new Date(newDate).getFullYear(), new Date(newDate).getMonth(), otherDueDate)
        ).format("YYYY-MM-DD");
      } else {
        let newPossibleDate = newDate;
        let count = 0;
        let reminder;
        // Search next possible date in future
        do {
          newPossibleDate = moment(newPossibleDate).add(1, "months").format("YYYY-MM");
          const timeDiff = moment(newPossibleDate).diff(moment(nextDueDate), "months", true);
          reminder = timeDiff % invoiceInterval;
          count++;
        } while (reminder != 0 && count < 12);

        const otherDueDate =
          otherDueDates === 100
            ? new Date(moment(newPossibleDate).endOf("month").format("YYYY-MM-DD")).getDate()
            : otherDueDates;

        dueDate = moment(
          new Date(
            new Date(newPossibleDate).getFullYear(),
            new Date(newPossibleDate).getMonth(),
            otherDueDate
          )
        ).format("YYYY-MM-DD");
      }
    }

    // Duedate cannot be less than today
    if (moment(moment().format("YYYY-MM-DD")).isSameOrAfter(dueDate)) {
      return moment().add(1, "days").format("YYYY-MM-DD");
    } else {
      return dueDate;
    }
  },

  calcRentIncreaseBillDate(baseInvoice, rentIncreaseDueDate) {
    const billDate = moment(rentIncreaseDueDate)
      .subtract(baseInvoice.currentRentIncrease.paymentTerm, "days")
      .format("YYYY-MM-DD");

    // Duedate cannot be after than today
    if (moment(moment().format("YYYY-MM-DD")).isSameOrAfter(billDate)) {
      return moment().add(1, "days").format("YYYY-MM-DD");
    } else {
      return billDate;
    }
  },

  getNewProductsWithoutId(products) {
    return products.map((el) => {
      if (el._id) delete el._id;

      if (el.productId && typeof el.productId == "object") {
        el.productId = el.productId._id;
      }
      return el;
    });
  },

  getReplyToEmail(val) {
    let email;
    if (val === "account") {
      email = store.state.account.currentUser.currentAccount.email;
    } else if (val === "user") {
      email = store.state.account.currentUser.email;
    } else {
      email = null;
    }
    return email;
  },

  getAllowedIndexDates(date, rentIncreaseType = "") {
    const dateWithoutYear = moment(date).format("MM");
    const allowedQMonths = ["03", "06", "09", "12"];

    if (rentIncreaseType.includes("propertyMaintenance")) {
      if (allowedQMonths.includes(dateWithoutYear)) return true;
      else return false;
    } else {
      return true;
    }
  },
};
