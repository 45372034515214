import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";
import mixins from "../mixins/mixins";
import allRoutes from "./routes/index.js";
import cleanUpList from "./routes/cleanUpList.js";

Vue.use(VueRouter);

const routes = [
  ...allRoutes,
  { path: "/rd", redirect: { name: "Sivua ei löydy" } },
  { path: "/lomakkeet", redirect: { name: "Sivua ei löydy" } },
  { path: "*", redirect: { name: "Yhteenveto" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    mixins.methods.scrollToTop();
  },
});

router.beforeResolve(async (to, from, next) => {
  // clean arrays in state before going to next route
  if (from && from.name in cleanUpList) {
    if (Array.isArray(cleanUpList[from.name].array)) {
      cleanUpList[from.name].array.forEach((arrayName) => {
        store.commit("cleanArrays", {
          module: cleanUpList[from.name].module,
          array: arrayName,
        });
      });
    } else {
      store.commit("cleanArrays", {
        module: cleanUpList[from.name].module,
        array: cleanUpList[from.name].array,
      });
    }
  }

  // IF NOT authorized data
  if (!to.meta.permission) {
    return next();
  } else {
    // AUTHDATA // No user or no token, return to login
    if (!store.state.user || !store.state.token) {
      await store.dispatch("deleteToken");
      return next("/login");
    } else {
      let routeAccess = store.state.userProfile.routePermissions;

      if (!routeAccess) {
        try {
          // Get user and set route access
          await store.dispatch("account/getCurrentUser");
          routeAccess = store.state.userProfile.routePermissions;

          // Get groupmode state
          const groupMode = store.state.groupMode;

          if (routeAccess[to.meta.permission]) {
            // If groupmode is not active, dont let user to go to group routes
            if (!groupMode && to.path.includes("/group/")) {
              return next("/overview");
            } else if (groupMode && !to.path.includes("/group/") && to.path !== "/profile") {
              return next("group/overview");
            }

            return next();
          } else {
            if (groupMode) {
              return next("/group/overview");
            } else {
              return next("/overview");
            }
          }
        } catch (err) {
          await store.dispatch("deleteToken");
          return next("/login");
        }
      } else {
        // Get groupmode state
        const groupMode = store.state.groupMode;

        if (routeAccess[to.meta.permission]) {
          // If groupmode is not active, dont let user to go to group routes
          if (!groupMode && to.path.includes("/group/")) {
            return next("/overview");
          } else if (
            groupMode &&
            !to.path.includes("/group/") &&
            to.path !== "/profile" &&
            to.path !== "/switch-account"
          ) {
            return next("/group/overview");
          }

          return next();
        } else {
          return next(from);
        }
      }
    }
  }
});

export default router;
