// Mixin util functions can be used in vue (imported in mixins) and js components

import moment from "moment";
import { translateWords } from "../language/translator";
import globalValues from "../configs/globalValues";

// Format currency
export const formatCurrency = (number, min = 2, max = 2) => {
  if (typeof number === "number") {
    return new Intl.NumberFormat("fi-FI", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    }).format(number);
  } else return " - €";
};

// Round decimals

export const roundDecimals = (num, decimals = 2) => {
  if (typeof num === "string") num = parseFloat(num);
  if (isNaN(num)) return NaN;

  const factor = Math.pow(10, decimals);
  const result = (Math.sign(num) * Math.round(Math.abs(num) * factor + Number.EPSILON)) / factor;
  return Object.is(result, -0) ? 0 : result;
};

// Get next account date
export const getNextAccountDate = (invoice, nextDueD = null) => {
  let accountDate;
  const nextDueDate = nextDueD ? nextDueD : invoice.nextDueDate;

  if (invoice.billDateAsDueDate) {
    if (nextDueDate) {
      accountDate = moment(
        new Date(new Date(nextDueDate).getFullYear(), new Date(nextDueDate).getMonth(), 1)
      ).format("YYYY-MM-DD");
    }
  } else {
    if (nextDueDate) {
      const nextDueDateMoment = moment(nextDueDate);
      const correctSendDate = nextDueDateMoment
        .subtract(invoice.paymentTerm, "days")
        .format("YYYY-MM-DD");
      accountDate = moment(correctSendDate).format("YYYY-MM-DD");
    }
  }

  // Account date cannot be in previous month
  const thisMonth = moment().format("YYYY-MM-DD");

  if (moment(accountDate).isSameOrAfter(moment(thisMonth).startOf("month").format("YYYY-MM-DD"))) {
    return accountDate;
  } else {
    return thisMonth;
  }
};

// Replace product tags
export const replaceProductTags = (productDesc, dueDate, accountDate, lang) => {
  const tags = [
    {
      text: "{{month_duedate}}",
      val: translateWords(globalValues.months[new Date(dueDate).getMonth()], lang, "months"),
    },
    {
      text: "{{month_accountdate}}",
      val: translateWords(globalValues.months[new Date(accountDate).getMonth()], lang, "months"),
    },
  ];

  tags.forEach((el) => {
    let reg = new RegExp(el.text, "g");
    productDesc = productDesc.replace(reg, el.val);
  });

  return productDesc;
};
