import { mapGetters, mapActions, mapMutations } from "vuex";
import { showPopup } from "../utils/helpers";
import globalValues from "../configs/globalValues";
import moment from "moment";
import integrations from "@/services/integration/index.js";
import validations from "@/validations.js";
import {
  getNextAccountDate as getNextAccountDateUtil,
  replaceProductTags as replaceProductTagsUtil,
} from "./utilFunctions";

export default {
  computed: {
    ...mapGetters("account", ["invoiceService"]),
    ...mapGetters(["getIsGroupMode"]),

    currentInvoiceService() {
      return this.invoiceService;
    },

    hasGlobalServiceAvailable() {
      return this.invoiceService && integrations[this.invoiceService] ? true : false;
    },

    isRopoService() {
      return this.invoiceService === "ropoCapital";
    },

    isTalenomService() {
      return this.invoiceService === "talenom";
    },

    currentInvoice() {
      return this.$store.state.invoice.currentInvoice;
    },

    costCentreFieldLabel() {
      const labelNames = {
        ropoCapital: "Ropo One kustannuspaikan id",
        talenom: "Kustannuspaikka",
      };

      if (this.hasGlobalServiceAvailable) {
        const service = this.currentInvoiceService;
        return labelNames[service] || "Kustannuspaikka";
      } else {
        return "Kustannuspaikka";
      }
    },
  },

  methods: {
    ...mapActions("deposit", ["getAttachedDeposits"]),
    ...mapActions("invoice", ["getInvoiceByServiceId", "updateSingleInvoiceStatuses"]),
    ...mapMutations("invoice", ["setLoadingStatuses"]),

    /* 
    //
        INTEGRATION BASED HELPERS
    //
    */

    hasServiceAvailable(service) {
      return service && integrations[service] ? true : false;
    },

    hasServiceId(invoice) {
      const service = invoice.service;
      if (this.hasServiceAvailable(service)) return integrations[service].hasServiceId(invoice);
      return false;
    },

    hasRefundServiceId(invoice) {
      const service = invoice.service;
      if (this.hasServiceAvailable(service))
        return integrations[service].hasRefundServiceId(invoice);
      return false;
    },

    contactHasServiceId(principalTenant) {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].contactHasServiceId(principalTenant);
      return false;
    },

    canDeleteInvoice(invoice) {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].canDeleteInvoice(invoice);
      return false;
    },

    canDownloadPreviewInvoice(invoice) {
      const service = invoice.service;
      if (this.hasServiceAvailable(service))
        return integrations[service].canDownloadPreviewInvoice(invoice);
      return false;
    },

    showChangeDueDateBtn() {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].showChangeDueDateBtn();
      return false;
    },

    canChangeDueDate(invoice = null) {
      const currentInvoice = invoice ? invoice : this.currentInvoice;
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].canChangeDueDate(currentInvoice);
      return false;
    },

    isDateAvailableForDueDateChange(date, billDate, dueDate) {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].isDateAvailableForDueDateChange(
          date,
          billDate,
          dueDate
        );
      return true;
    },

    getDueDateWarningText() {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].getDueDateWarningText();
      return "";
    },

    getServiceInvoiceLanguages() {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].getServiceInvoiceLanguages(
          globalValues.languages
        );
      return { text: "Suomi", val: "fin" };
    },

    canUpdateTenantDataToService(tenant) {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].canUpdateTenantDataToService(tenant);
      return false;
    },

    isInvoiceAddressCountryAvailable(country) {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].isInvoiceAddressCountryAvailable(
          country,
          globalValues.availableInvoiceAddressCountries
        );
      return false;
    },

    canUpdateInvoiceStatuses(invoice) {
      if (this.hasGlobalServiceAvailable)
        return integrations[this.currentInvoiceService].canUpdateInvoiceStatuses(invoice);
      return false;
    },

    async getSingleInvoiceStatuses(invoice) {
      try {
        // Loading on
        this.setLoadingStatuses(true);

        let data;

        if (this.hasGlobalServiceAvailable)
          data = integrations[this.currentInvoiceService].getSingleInvoiceUpdateData(invoice);
        else throw Error("Laskuja ei voitu tarkistaa");

        await this.updateSingleInvoiceStatuses(data);
        showPopup("Laskun tiedot päivitetty", "success");

        //  Loading off
        this.setLoadingStatuses(false);
      } catch (err) {
        this.setLoadingStatuses(false);
        showPopup(err, "error");
      }
    },

    async getAttachedDepositsAndOriginalInvoice(invoice) {
      let serviceId;
      let originalInvoice = null;

      if (this.hasGlobalServiceAvailable)
        serviceId = integrations[this.currentInvoiceService].getRefundServiceId(invoice);
      else return;

      if (serviceId) {
        this.getAttachedDeposits(serviceId);
        originalInvoice = await this.getInvoiceByServiceId(serviceId);
      }

      return { originalInvoice };
    },

    getInvoiceSearchLabelByService(invoiceType) {
      const isGroupMode = this.getIsGroupMode || false;
      let text = "Laskunro, saaja, vuokrakohde, kohteennumero";

      if (isGroupMode) {
        switch (invoiceType) {
          case "invoice":
            text = "Laskunro, ropoId, talenomId, saaja, saatavat, vuokrakohde, kohteen numero...";
            break;
          case "refundInvoice":
            text = "Laskunro, vuokralainen, hyvitys, vuokrakohde, kohteen numero...";
            break;
        }
      } else {
        if (this.hasGlobalServiceAvailable)
          text =
            integrations[this.currentInvoiceService].getInvoiceSearchLabelByService(invoiceType);
      }

      return text;
    },

    getInvoiceState(invoice) {
      const service = invoice.service;
      if (this.hasServiceAvailable(service)) return integrations[service].getInvoiceState(invoice);
      return "";
    },

    showBasedOnService(obj, service = null) {
      const currentService = service || this.invoiceService;
      if (!currentService) return true;
      return obj[currentService] !== undefined ? obj[currentService] : true;
    },

    isRequiredByInvoiceService(rulesObj, service) {
      const currentService = service || this.invoiceService;

      if (!currentService) return () => true;

      if (rulesObj[currentService]) {
        return (v) => !!v || "Pakollinen kenttä";
      } else {
        return () => true;
      }
    },

    maxCharacters(rulesObj) {
      if (this.hasGlobalServiceAvailable) {
        if (rulesObj[this.currentInvoiceService]) {
          return (v) => {
            if (v) {
              if (rulesObj[this.currentInvoiceService] >= v.length) return true;
              else return `Max merkkimäärä ${rulesObj[this.currentInvoiceService]}`;
            } else {
              return true;
            }
          };
        }
      }
      return true;
    },

    validateInvoiceProductCount(isRefundForm) {
      if (this.hasGlobalServiceAvailable) {
        return integrations[this.currentInvoiceService].validateInvoiceProductCount(isRefundForm);
      } else {
        return isRefundForm
          ? validations.required.concat(validations.negativeNumber)
          : validations.required.concat(validations.positiveNumber);
      }
    },

    // canUsePersonalRefNum(invoice, principalTenant) {
    //   if (this.hasGlobalServiceAvailable)
    //     return integrations[this.currentInvoiceService].canUsePersonalRefNum(
    //       invoice,
    //       principalTenant
    //     );
    //   else return true;
    // },

    /* 
    //
       END OF INTEGRATION BASED HELPERS
    //
    */

    /* 
    //
        OTHER HELPERS
    //
    */

    getInvoiceStateClass(state) {
      let className = "";
      switch (state) {
        case "Hyvitys":
          className = "warning--text";
          break;
        case "Maksettu":
          className = "success--text";
          break;
        case "Laskutus":
          className = "success--text";
          break;
        case "Muistutus":
          className = "warning--text";
          break;
        case "Muistutuskysely":
          className = "warning--text";
          break;
        case "Perintä":
          className = "error--text";
          break;
      }
      return className;
    },

    replaceProductTags(productDesc, dueDate, accountDate, lang) {
      return replaceProductTagsUtil(productDesc, dueDate, accountDate, lang);
    },

    getNextAccountDate(invoice, nextDueD = null) {
      return getNextAccountDateUtil(invoice, nextDueD);
    },

    getFixedPeriodDueDate(invoice) {
      if (
        invoice.fixedPeriodRecurrency.active ||
        moment(invoice.fixedPeriodRecurrency.startDate).isSame(
          moment(moment(invoice.nextDueDate).format("YYYY-MM"))
        )
      ) {
        return invoice.nextDueDate;
      } else {
        const year = new Date(invoice.fixedPeriodRecurrency.startDate).getFullYear();
        const month = new Date(invoice.fixedPeriodRecurrency.startDate).getMonth();

        if (invoice.otherDueDates === 100) {
          return moment(new Date(year, month, this.calcLastDayOfMonthDate(year, month))).format(
            "YYYY-MM-DD"
          );
        } else {
          return moment(new Date(year, month, invoice.otherDueDates)).format("YYYY-MM-DD");
        }
      }
    },

    getFixedPeriodAccountDate(invoice, startDateExact) {
      let compareDate = startDateExact ? startDateExact : invoice.nextDueDate;

      let accountDate;
      if (invoice.billDateAsDueDate) {
        accountDate = moment(compareDate).format("YYYY-MM-DD");
      } else {
        accountDate = moment(compareDate)
          .subtract(invoice.paymentTerm, "days")
          .format("YYYY-MM-DD");
      }

      // Account date cannot be in previous month
      const thisMonth = moment().format("YYYY-MM-DD");

      if (
        moment(accountDate).isSameOrAfter(moment(thisMonth).startOf("month").format("YYYY-MM-DD"))
      ) {
        return accountDate;
      } else {
        return thisMonth;
      }
    },

    formatCommentsInTableRow(comments) {
      if (comments) {
        let lastComment;
        if (comments.length > 0) lastComment = comments[comments.length - 1].title;
        else lastComment = "Ei uusia viestejä";
        return lastComment;
      } else {
        return "Ei uusia viestejä";
      }
    },
  },
};
