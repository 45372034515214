import Vue from "vue";

// Only numbers with no decimals are valid

Vue.directive("only-numbers", {
  bind: function (el) {
    el.addEventListener("keypress", (e) => {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      const charStr = String.fromCharCode(keyCode);
      const inputElementVal = el.nodeName == "INPUT" ? el : el.querySelector("input").value;

      // First number 0 is not accepted and only one minus
      if (
        (inputElementVal == "" && keyCode == 48) ||
        (charStr == "-" && inputElementVal.includes("-"))
      ) {
        e.preventDefault();
      }

      // Only numbers and not dots
      if (keyCode == 46 || keyCode < 48 || keyCode > 57) {
        if (charStr != "-") e.preventDefault(); // - (minus) is acceptable
      }
    });
  },
});

// Only numbers with no decimals are valid

Vue.directive("only-numbers-and-zero", {
  bind: function (el) {
    el.addEventListener("keypress", (e) => {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      const charStr = String.fromCharCode(keyCode);
      const inputElementVal = el.nodeName == "INPUT" ? el : el.querySelector("input").value;

      // Only one minus
      if (
        (charStr == "-" && inputElementVal.includes("-")) ||
        (keyCode == 48 && inputElementVal == "0")
      ) {
        e.preventDefault();
      }

      // Only numbers and not dots
      if (keyCode < 48 || keyCode > 57) {
        if (charStr != "-") e.preventDefault(); // - (minus) is acceptable
      }
    });
  },
});

// Positive price

Vue.directive("positive-price", {
  bind: function (el) {
    el.addEventListener("keypress", (e) => {
      const value = e.target.value;
      const i = value.indexOf(".");

      // Not big numbers
      if (e.target.value.length > 15) {
        e.preventDefault();
      }

      if (!oneDotAndNumbers(e) && Number(value) > 0) {
        e.preventDefault();
      } else if (
        e.target.selectionStart == e.target.selectionEnd &&
        (i == -1 || e.target.selectionEnd > i) &&
        value.split(".")[1]?.length > 1
      ) {
        e.preventDefault();
      }
    });
  },
});

// Prevent paste

Vue.directive("prevent-paste", {
  bind: function (el) {
    el.addEventListener("paste", (e) => {
      e.preventDefault();
    });
  },
});

// Prevent manual input

Vue.directive("prevent-manual-input", {
  bind: function (el) {
    el.addEventListener("input", (e) => {
      e.preventDefault();
    });
    el.addEventListener("paste", (e) => {
      e.preventDefault();
    });
    el.addEventListener("keypress", (e) => {
      e.preventDefault();
    });
  },
});

// Helpers

function oneDotAndNumbers(e) {
  const i = e.target.value.indexOf(".");
  const keyCode = e.keyCode ? e.keyCode : e.which;

  return (keyCode < 48 || keyCode > 57) && (keyCode !== 46 || i != -1) ? false : true;
}
